<template>
    <div class="">

        <index :isLoadingFlow="isLoadingCreateFlow" :flowDetail="flowDetail" @fetchFlow="getCurrFlow"></index>
    </div>
</template>
<script>
import index from './index.vue'
import { mapActions } from 'vuex';
export default {
    components:{
        index
    },
    data(){
        return {
            isLoadingCreateFlow:false,
            flowDetail:{

}
        }
    },
    methods:{
        ...mapActions(['fetchData']),
        async getCurrFlow(){
            
           this.isLoadingCreateFlow=true;
         
         const data = await this.fetchData({
                axiosMethod:'get',
                resource:'flow',
                method:'edit',
                id: this.$route.params.id
            })
           
            this.isLoadingCreateFlow=false
            if(data)

            this.flowDetail=data
       
           
        },
    
      
    },
  

    mounted(){
        this.getCurrFlow()
    }

}
</script>